import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { UpdateCompanyAction, DownloadFileForAdmin2Action } from '../../../redux/companies/companies-actions'
import clsx from 'clsx'
import Help from '@mui/icons-material/Help'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Collapse, IconButton, FormControlLabel, ListItemSecondaryAction } from '@mui/material'
import { CsvType } from '../../../redux/companies/companies-types'
import { FetchCompaniesAction, UpdateIntegrationAction } from '../../../redux/admin/admin-actions'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => {
  return {
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(90deg)'
    },
    textField: {
      marginBottom: '10px',
      marginTop: '10px'
    }
  }
})

const Integrations = ({ dispatch, companies }) => {
  const [expanded, setExpanded] = useState({})
  const { classes } = useStyles()
  const t = useTranslation().t

  useEffect(() => {
    if (companies.length === 0) {
      dispatch(FetchCompaniesAction())
    }
  }, [dispatch, companies])

  const handleIntegrationDataChange = (company, property, event) => {
    const updatedData = Object.assign({},
      company, {
        integration: {
          ...company.integration,
          [property]: event.target.value
        }
      })
    dispatch(UpdateIntegrationAction({
      companyId: company.id,
      data: updatedData
    }))
  }

  return (
    <div style={{ margin: '0 auto', paddingTop: '20px', paddingBottom: '100px', width: '600px' }}>
      {companies.filter((company) => company.name !== 'NOT_ASSIGNED_USERS').sort((a, b) => a.name > b.name).map((company) => {
        return (
          <Card key={company.id} style={{ marginBottom: '10px' }}>
            <CardHeader
              title={company.name}
              subheader={company.integration ? company.integration.integrationType : ''}
              action={
                <IconButton
                  aria-expanded={expanded[company.id]}
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded[company.id]
                  })}
                  onClick={() => {
                    if (expanded[company.id] === undefined) {
                      setExpanded({ ...expanded, [company.id]: true })
                    } else {
                      setExpanded({ ...expanded, [company.id]: !expanded[company.id] })
                    }
                  }}
                  size='large'
                >
                  <KeyboardArrowRight />
                </IconButton>
              }
            />
            <Collapse in={expanded[company.id]} timeout='auto'>
              <CardContent>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={company.integration ? (company.integration.enabled || false) : false}
                      value='integrationEnabled'
                      onChange={(event) => {
                        handleIntegrationDataChange(company, 'enabled', event)
                      }}
                      color='primary'
                    />
                  }
                  label={t('settings_o.enabled')}
                />
                <TextField
                  id={company.id}
                  label='Ajastus (Quartz Cron)'
                  defaultValue={company.integration ? company.integration.cronString : ''}
                  fullWidth
                  className={classes.textField}
                  onBlur={(event) => handleIntegrationDataChange(company, 'cronString', event)}
                  variant='standard'
                />
                <Select
                  autoWidth={false}
                  value={(company.integration && company.integration.integrationType) ? company.integration.integrationType : ''}
                  style={{ width: '100%' }}
                  onChange={(event) => {
                    handleIntegrationDataChange(company, 'integrationType', event)
                  }}
                  variant='standard'
                >
                  <MenuItem value=''>{t('nothing')}</MenuItem>
                  <MenuItem value='AQUA'>AQUA</MenuItem>
                  <MenuItem value='IN_PULSE'>IN_PULSE</MenuItem>
                  <MenuItem value='VESIKANTA'>VESIKANTA</MenuItem>
                  <MenuItem value='VESITIETO'>VESITIETO</MenuItem>
                </Select>
                {company.integration && company.integration.integrationType === 'IN_PULSE' &&
                  <List>
                    <ListItem key={`in_pulse_format_${company.id}`}>
                      <ListItemSecondaryAction>
                        <Button><Help /></Button>
                      </ListItemSecondaryAction>
                      <TextField
                        key={`${company.id}inpulse`}
                        id={`${company.id}inpulse`}
                        label='IN_PULSE Format'
                        defaultValue={company.integration ? company.integration.inPulseFormat : ''}
                        fullWidth
                        onBlur={(event) => handleIntegrationDataChange(company, 'inPulseFormat', event)}
                        variant='standard'
                      />
                    </ListItem>
                  </List>}
                <TextField
                  id={`${company.id}url`}
                  label='URL'
                  defaultValue={company.integration ? company.integration.url : ''}
                  fullWidth
                  onBlur={(event) => handleIntegrationDataChange(company, 'url', event)}
                  variant='standard'
                />
                <TextField
                  id={`${company.id}username`}
                  label={t('username')}
                  defaultValue={company.integration ? company.integration.username : ''}
                  fullWidth
                  onBlur={(event) => handleIntegrationDataChange(company, 'username', event)}
                  variant='standard'
                />
                <TextField
                  id={`${company.id}password`}
                  label={t('password')}
                  type='text'
                  defaultValue=''
                  fullWidth
                  onBlur={(event) =>
                    handleIntegrationDataChange(company, 'password', event)}
                  variant='standard'
                />
                <TextField
                  id={`${company.id}tietoIdentifier`}
                  label='Tieto Identifier'
                  type='text'
                  defaultValue={company.integration ? company.integration.tietoIdentifier : ''}
                  fullWidth
                  onBlur={(event) => handleIntegrationDataChange(company, 'tietoIdentifier', event)}
                  variant='standard'
                />
              </CardContent>
              <CardActions>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => dispatch(UpdateCompanyAction({ id: company.id, data: company }))}
                >
                  {t('common.actions.save')}
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => dispatch(DownloadFileForAdmin2Action({ companyId: company.id, useGeneric: CsvType.COMPANY_SPECIFIC }))}
                >
                  {t('generate_file')}
                </Button>
              </CardActions>
            </Collapse>
          </Card>
        )
      })}
    </div>
  )
}

function mapStateToProps (state) {
  const { username, isFetchingUserInformation } = state.vesimittari.auth
  const { companies } = state.vesimittari.admin

  return {
    username,
    isFetchingUserInformation,
    companies
  }
}

export default connect(mapStateToProps)(Integrations)
