import React from 'react'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { FormControl, InputLabel, Grid } from '@mui/material'

const Field = ({ deviceForProvision, label, field, valueChanged }) => (
  <Grid item xs>
    <TextField
      fullWidth
      id={`dfp-${field}`}
      value={deviceForProvision && deviceForProvision[field]}
      onChange={(event) => valueChanged(field, event.target.value)}
      label={label}
      variant='standard'
    />
  </Grid>
)

const DeviceForProvisionFields = ({ deviceForProvision, valueChanged, deviceProfiles, loadingDeviceProfiles }) => (
  <div>
    <Grid item container spacing={2}>
      <Field deviceForProvision={deviceForProvision} label='Serial Number' field='serialNumber' valueChanged={valueChanged} />
      <Field deviceForProvision={deviceForProvision} label='Dev EUI' field='devEUI' valueChanged={valueChanged} />
    </Grid>
    <Grid item container>
      <Field deviceForProvision={deviceForProvision} label='App EUI' field='appEUI' valueChanged={valueChanged} />
    </Grid>
    <Grid item container>
      <Grid item xs>
        <TextField
          fullWidth
          id='dfp-appKey'
          value={deviceForProvision && deviceForProvision.appKey}
          onChange={(event) => valueChanged('appKey', event.target.value)}
          label='App key'
          variant='standard'
        />
      </Grid>
    </Grid>
    <Grid item container style={{ paddingTop: '15px' }}>
      <Grid item xs>
        {loadingDeviceProfiles &&
          <span>Ladataan profiileja...</span>}
        {!loadingDeviceProfiles && (
          <FormControl style={{ width: '100%' }}>
            <InputLabel>
              Valitse profiili
            </InputLabel>
            <Select
              fullWidth
              value={deviceForProvision.deviceProfileId}
              onChange={(event) => valueChanged('deviceProfileId', event.target.value)}
              variant='standard'
            >
              {deviceProfiles.map((c, i) => <MenuItem key={c.id + c.name + c.typeMAC + i} value={c.id}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>
        )}
      </Grid>
    </Grid>
  </div>
)

export default DeviceForProvisionFields
