import React from 'react'
import moment from 'moment'
import { Bar, Line } from 'react-chartjs-2'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip
} from '@mui/material'

import {
  ColoredLabel,
  ColoredValue,
  GraphIconButton,
  StyledAppBar,
  StyledInput,
  StyledTableCell,
  TableHeadCellBordered,
  useTabStyles
} from './MetersTableRow.style'
import SettingsIcon from '@mui/icons-material/Settings'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import BarChart from '@mui/icons-material/BarChart'

import { useDispatch, useSelector } from 'react-redux'

import { type RootState } from '../../../../interfaces/RootState'

import DownlinkMessages from '../../../common/downlink-messages/DownlinkMessages'
import { FetchDownlinkMessagesForDeviceAction } from '../../../../redux/downlink-messages/downlink-messages-actions'
import MetersTableLabelsField from '../MetersTableLabelsField'
import { StyledLabel } from '../../meter-configuration/MeterConfiguration.style'
import { DeviceType } from '../../../../redux/meters/meter-types'
import { meterSupportsAlarm } from '../../../../utils/meter-utils'
import { FetchMeterHistoryValuesAction } from '../../../../redux/meter-data/meter-data-actions'
import { useTranslation } from 'react-i18next'
import { Chart, registerables } from 'chart.js'
import { isTimestampOverDayOld } from '../../../../utils/utils'
import { FLOW_LIMIT, getAlarmTypeByKey } from '../../../../redux/alarm-settings/alarm-types'
import { AlarmsDetails, AlarmStatusIcon } from './AlarmsDetails'
import { type Alarm } from './types'

Chart.register(...registerables)

const graphOptions = {
  scales: {
    x: {
      grid: {
        display: false
      }
    }
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: {
      intersect: false
    },
    legend: {
      display: false
    }
  },
  data: {
    datasets: {
      line: {
        radius: 1
      }
    }
  }
}

export default function MetersTableRow({
  meter,
  logo,
  metersSettingDialog,
  dateAndTimeUpdating,
  dateAndTimeCoupling,
  meterLocation,
  updatedValue,
  idVisibility,
  usageVisibility,
  isPulse,
  loraData,
  labelVisibility
}) {
  const dispatch = useDispatch()
  const vesimittariComponent = useSelector(
    (state: RootState) => state.vesimittari.meterTable
  )
  const meterDataState = useSelector(
    (state: RootState) => state.vesimittari.meterDataReducer
  )
  const downlinkMessagesByDeviceId = useSelector(
    (state: RootState) =>
      state.vesimittari.downlinkMessagesReducer.downlinkMessagesByDeviceId
  )
  let downlinkMessagesCount = '?'
  if (downlinkMessagesByDeviceId[meter.id] !== undefined) {
    downlinkMessagesCount = `${
      downlinkMessagesByDeviceId[meter.id].sentMessages.length +
      downlinkMessagesByDeviceId[meter.id].queuedMessages.length
    }`
  }
  const [currentTab, setCurrentTab] = React.useState<number | boolean>(false)
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false)
  const [tabsExpanded, setTabsExpanded] = React.useState<boolean>(false)
  const [graphSourceType, setGraphSourceType] = React.useState<string>('water')
  const [graphStartDate, setGraphStartDate] = React.useState<moment.Moment>(
    moment().startOf('month')
  )
  const [graphEndDate, setGraphEndDate] = React.useState<moment.Moment>(
    moment().endOf('month')
  )
  const [graphScale, setGraphScale] = React.useState<string>('day')

  const t = useTranslation().t

  const { classes } = useTabStyles()

  const isMeterOutdated = isTimestampOverDayOld(meter.updatedAt)

  const alarms: Alarm[] = []

  const TabPanel = ({ children, tabId }) => {
    return <div hidden={currentTab !== tabId}>{children}</div>
  }

  const getTextDecoration = (): string =>
    meter.deleted ? 'line-through' : 'inherit'
  const getBackgroundColor = (): string =>
    isMeterOutdated || meter.deleted ? '#f7dfdf' : 'inherit'

  const changeGraphScale = (scale: string) => {
    setGraphScale(scale)
    let newStartDate, newEndDate
    switch (scale) {
      case 'single':
        newStartDate = moment().locale('fi').startOf('day')
        newEndDate = newStartDate.clone().endOf('day')
        break
      case 'day':
        newStartDate = moment().locale('fi').startOf('month')
        newEndDate = newStartDate.clone().endOf('month')
        break
      case 'week':
        newStartDate = moment()
          .locale('fi')
          .subtract(3, 'weeks')
          .startOf('week')
        newEndDate = moment().endOf('week')
        break
      case 'month':
        newStartDate = moment().locale('fi').startOf('year')
        newEndDate = newStartDate.clone().endOf('year')
        break
    }
    setGraphStartDate(newStartDate)
    setGraphEndDate(newEndDate)
    dispatch(
      FetchMeterHistoryValuesAction({
        id: meter.id,
        start: newStartDate,
        end: newEndDate,
        scale
      })
    )
  }

  const moveGraphDatesBack = () => {
    let newStartDate, newEndDate
    switch (graphScale) {
      case 'single':
        newStartDate = graphStartDate
          .clone()
          .subtract(1, 'days')
          .startOf('day')
        newEndDate = newStartDate.clone().endOf('day')
        break
      case 'day':
        newStartDate = graphStartDate
          .clone()
          .subtract(1, 'months')
          .startOf('month')
        newEndDate = newStartDate.clone().endOf('month')
        break
      case 'week':
        newStartDate = graphStartDate
          .clone()
          .subtract(4, 'weeks')
          .startOf('week')
        newEndDate = newStartDate.clone().add(3, 'weeks').endOf('week')
        break
      case 'month':
        newStartDate = graphStartDate
          .clone()
          .subtract(1, 'years')
          .startOf('year')
        newEndDate = newStartDate.clone().endOf('year')
        break
      default:
        newStartDate = moment()
        newEndDate = moment()
    }
    setGraphStartDate(newStartDate)
    setGraphEndDate(newEndDate)
    dispatch(
      FetchMeterHistoryValuesAction({
        id: meter.id,
        start: newStartDate,
        end: newEndDate,
        scale: graphScale
      })
    )
  }

  const moveGraphDatesForward = () => {
    let newStartDate, newEndDate
    switch (graphScale) {
      case 'single':
        newStartDate = graphStartDate.clone().add(1, 'days').startOf('day')
        newEndDate = newStartDate.clone().endOf('day')
        break
      case 'day':
        newStartDate = graphStartDate.clone().add(1, 'months').startOf('month')
        newEndDate = newStartDate.clone().endOf('month')
        break
      case 'week':
        newStartDate = graphStartDate.clone().add(4, 'weeks').startOf('week')
        newEndDate = newStartDate.clone().add(3, 'weeks').endOf('week')
        break
      case 'month':
        newStartDate = graphStartDate.clone().add(1, 'years').startOf('year')
        newEndDate = newStartDate.clone().endOf('year')
        break
      default:
        newStartDate = moment()
        newEndDate = moment()
    }
    setGraphStartDate(newStartDate)
    setGraphEndDate(newEndDate)
    dispatch(
      FetchMeterHistoryValuesAction({
        id: meter.id,
        start: newStartDate,
        end: newEndDate,
        scale: graphScale
      })
    )
  }

  const getDisplayDate = () => {
    switch (graphScale) {
      case 'single':
        return graphEndDate.format('DD/MM/YYYY')
      case 'day':
        return graphEndDate.format('MM/YYYY')
      case 'week':
        return `${graphStartDate.format('WW')}-${graphEndDate.format('WW')}`
      case 'month':
        return graphEndDate.format('YYYY')
    }
  }

  const getLabelChips = () => {
    if (!meter.labels) {
      return null
    }

    return meter.labels.map((i) => <StyledLabel label={i} key={i} />)
  }

  const getTooltip = (): string => {
    if (meter.deleted) {
      return t('settings_dialog.meter_deleted')
    } else if (isMeterOutdated) {
      return t('settings_dialog.meter_outdated')
    }

    return ''
  }

  const expandMeter = () => {
    if (!isExpanded) {
      // Row expanded! Load the graph data
      dispatch(
        FetchMeterHistoryValuesAction({
          id: meter.id,
          start: graphStartDate,
          end: graphEndDate,
          scale: graphScale
        })
      )
      dispatch(FetchDownlinkMessagesForDeviceAction({ deviceId: meter.id }))
    }

    vesimittariComponent.expandedMeter = meter.id
    setIsExpanded(!isExpanded)
  }

  const switchTab = (_: React.ChangeEvent<object>, newTab: number) => {
    setCurrentTab(newTab)

    if (!tabsExpanded) {
      // Open tabs if they aren't already open (saves 1 click from user)
      setTabsExpanded(true)
    }
  }

  if (loraData !== null) {
    for (const i in loraData.status) {
      const alarmType = getAlarmTypeByKey(i)
      const supported = meterSupportsAlarm(
        meter.type,
        alarmType
      )
      alarms.push({
        type: alarmType,
        active: supported && loraData.status[i],
        supported
      })
    }
  }

  const flowLimitIsSupported = meterSupportsAlarm(
    DeviceType[meter.type],
    FLOW_LIMIT
  )

  // Prevent multiple meters from being expanded at the same time
  if (isExpanded && vesimittariComponent.expandedMeter !== meter.id) {
    setIsExpanded(false)
  }

  return (
    <>
      <Tooltip style={{ fontSize: '12em' }} arrow title={getTooltip()}>
        <TableRow
          key={meter.id}
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(9,1fr)',
            backgroundColor: getBackgroundColor(),
            textDecoration: getTextDecoration()
          }}
        >
          <StyledTableCell>
            <img src={logo} alt="lora-logo" style={{ width: '35px' }} />
            {isPulse}
          </StyledTableCell>
          <StyledTableCell>
            <StyledInput
              isIdInput={true}
              readOnly
              value={meter.sigfoxId || ''}
              style={idVisibility}
            />
          </StyledTableCell>
          <StyledTableCell>
            <StyledInput
              isIdInput={true}
              readOnly
              style={usageVisibility}
              value={meterLocation || ''}
            />
          </StyledTableCell>
          <StyledTableCell>
            <MetersTableLabelsField
              metersLabel={meter.labels}
              metersSettingDialog={metersSettingDialog}
              labelVisibility={labelVisibility}
            />
          </StyledTableCell>
          <StyledTableCell>
            <AlarmStatusIcon
              alarms={alarms}
              alarmSettings={meter.alarmSettings}
            />
          </StyledTableCell>
          <StyledTableCell>{updatedValue}</StyledTableCell>
          <StyledTableCell>{dateAndTimeCoupling}</StyledTableCell>
          <StyledTableCell>{dateAndTimeUpdating}</StyledTableCell>
          <StyledTableCell
            style={{ display: 'flex', justifyContent: 'space-evenly' }}
          >
            <GraphIconButton
              onClick={() => { expandMeter() }}
              style={{ paddingTop: '6px' }}
              data-testid="meter-expand-button"
            >
              {isExpanded ? <KeyboardArrowDown /> : <BarChart />}
            </GraphIconButton>
            <SettingsIcon
              onClick={metersSettingDialog}
              style={{ cursor: 'pointer', color: '#6e84a3' }}
            />
          </StyledTableCell>
        </TableRow>
      </Tooltip>
      {isExpanded
        ? (
        <TableRow style={{ padding: '20px' }}>
          {/* TODO: <div> inside <tr> is not legal HTML */}
          <Grid container style={{ display: 'block' }}>
            {/* BASIC INFO */}
            <Grid item xs container spacing={1} direction="row">
              <Grid item xs={3}>
                <ColoredLabel>Sarjanumero</ColoredLabel>
                <ColoredValue>{meter.transmitterSerialNumber}</ColoredValue>
              </Grid>
              <Grid item xs={3}>
                <ColoredLabel>{t('serial_number')}</ColoredLabel>
                <ColoredValue>{meter.serialNumber}</ColoredValue>
              </Grid>
              <Grid item xs={3}>
                <ColoredLabel>{t('serial_number_generic')}</ColoredLabel>
                {getLabelChips()}
              </Grid>
            </Grid>

            {/* GRAPH DATA */}
            <Grid
              item
              xs
              spacing={1}
              container
              direction="row"
              style={{ marginTop: '2em' }}
            >
              <Grid item xs={6} style={{ color: '#2B3B55' }}>
                <span style={{ marginRight: '1em', fontSize: '12px' }}>
                  {t('graph_dialog.usage_history')}
                </span>
                <b>
                  {`${graphStartDate.format(
                    'DD.MM.YYYY'
                  )} - ${graphEndDate.format('DD.MM.YYYY')}`}
                </b>
              </Grid>
              <Grid item xs={2}>
                <Select
                  className={classes.graphSelects}
                  value={graphSourceType}
                  onChange={(e) => { setGraphSourceType(e.target.value) }}
                  variant="standard"
                >
                  <MenuItem value={'water'} key={'water'}>
                    <span style={{ margin: '0 1em 0 1em' }}>{t('graph_dialog.water_usage')}</span>
                  </MenuItem>
                  <MenuItem value={'temperature'} key={'temperature'}>
                    <span style={{ margin: '0 1em 0 1em' }}>{t('temperature')}</span>
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Select
                  className={classes.graphSelects}
                  onChange={(e) => { changeGraphScale(e.target.value) }}
                  value={graphScale}
                  variant="standard"
                >
                  <MenuItem value={'single'} key={'single'}>
                    <span style={{ margin: '0 1em 0 1em' }}>
                      {t('graph_dialog.detailed_usage')}
                    </span>
                  </MenuItem>
                  <MenuItem value={'day'} key={'day'}>
                    <span style={{ margin: '0 1em 0 1em' }} data-testid='daily-usage'>{t('graph_dialog.daily_usage')}</span>
                  </MenuItem>
                  <MenuItem value={'week'} key={'week'}>
                    <span style={{ margin: '0 1em 0 1em' }}>{t('graph_dialog.weekly_usage')}</span>
                  </MenuItem>
                  <MenuItem value={'month'} key={'month'}>
                    <span style={{ margin: '0 1em 0 1em' }}>
                      {t('graph_dialog.monthly_usage')}
                    </span>
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={2}>
                <div style={{ float: 'right', height: '2em' }}>
                  <IconButton
                    className={classes.graphControls}
                    onClick={() => { moveGraphDatesBack() }}
                    size="large"
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <Button
                    style={{ height: '24px' }}
                    variant="contained"
                    className={classes.graphControls}
                    disableElevation
                    disableFocusRipple
                    disableRipple
                  >
                    {getDisplayDate()}
                  </Button>
                  <IconButton
                    className={classes.graphControls}
                    onClick={() => { moveGraphDatesForward() }}
                    size="large"
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </div>
              </Grid>
            </Grid>

            {/* GRAPH ITSELF */}
            <Grid
              item
              xs
              spacing={1}
              container
              direction="row"
              style={{ marginTop: '1em' }}
            >
              <Grid xs={12}>
                {meterDataState.fetchingHistoryGraphData && (
                  <CircularProgress
                    style={{ marginLeft: 'calc(50% - 20px)' }}
                  />
                )}
                {graphSourceType === 'water' &&
                  !meterDataState.fetchingHistoryGraphData && (
                  <Bar
                    data={meterDataState.historyGraphData.water}
                    options={graphOptions}
                  />
                )}
                {graphSourceType === 'temperature' &&
                  !meterDataState.fetchingHistoryGraphData && (
                  <Line
                    data={meterDataState.historyGraphData.temperature}
                    options={graphOptions}
                  />
                )}
              </Grid>
            </Grid>

            {/* TABS */}
            <Grid
              item
              xs
              spacing={1}
              container
              direction="row"
              style={{ marginTop: '2em' }}
            >
              <Grid
                item
                xs
                style={{
                  border: '1px #EDF2F9',
                  borderRadius: '4px 4px 0 0',
                  borderStyle: 'solid',
                  padding: '0'
                }}
              >
                <StyledAppBar position="static">
                  <Tabs
                    value={currentTab === null ? false : currentTab}
                    className={classes.root}
                    onChange={switchTab}
                  >
                    <Tab
                      disableRipple
                      label={`${t('alarms')}: ${
                        alarms.filter((a) => a.active && a.supported).length
                      }`}
                    />
                    <Tab
                      disableRipple
                      label={`${t('messages')}: ${downlinkMessagesCount}`}
                    />
                    <IconButton
                      disableRipple
                      className={classes.tabsLastElement}
                      onClick={() => { setTabsExpanded(!tabsExpanded) }}
                      size="large"
                    >
                      {tabsExpanded
                        ? (
                        <KeyboardArrowDown fontSize="large" />
                          )
                        : (
                        <KeyboardArrowRight fontSize="large" />
                          )}
                    </IconButton>
                  </Tabs>
                </StyledAppBar>

                {tabsExpanded && (
                  <>
                    {/* ALARMS */}
                    <TabPanel tabId={0}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableHeadCellBordered>{'#'}</TableHeadCellBordered>
                            <TableHeadCellBordered>
                              {t('alarm')}
                            </TableHeadCellBordered>
                            <TableHeadCellBordered>
                              {t('description')}
                            </TableHeadCellBordered>
                            <TableHeadCellBordered>
                              {t('state')}
                            </TableHeadCellBordered>
                          </TableHead>
                          <TableBody>
                            {alarms.map((i: Alarm, index: number) => (
                              <AlarmsDetails
                                alarm={i}
                                index={index}
                              />
                            ))
                            }
                            <AlarmsDetails
                                alarm={{
                                  type: FLOW_LIMIT,
                                  active: flowLimitIsSupported && loraData.flowLimit,
                                  supported: flowLimitIsSupported
                                }}
                                index={
                                  alarms.lastIndexOf(alarms[alarms.length - 1]) +
                                  1
                              }
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>

                    {/* MESSAGES */}
                    <TabPanel tabId={1}>
                      <DownlinkMessages meterId={meter.id} />
                    </TabPanel>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </TableRow>
          )
        : null}
    </>
  )
}
