import React from 'react'
import { connect } from 'react-redux'

import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'

import DeviceForProvisionFields from './DeviceForProvisionFields'

import {
  closeEditDeviceForProvisionDialog,
  updateEditedDeviceForProvision,
  editedDeviceForProvisionValuesChanged,
  deleteDeviceForProvision
} from '../../../redux/devices-for-provision/devices-for-provision-actions'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { withTranslation } from 'react-i18next'

class EditDeviceForProvisionDialog extends React.Component {
  closeDialog () {
    this.props.dispatch(closeEditDeviceForProvisionDialog())
  }

  toggleConfirmDelete () {
    this.setState({ deleteConfirmed: this.state.deleteConfirmed })
  }

  disabled () {
    return !this.props.editedDeviceForProvision.serialNumber ||
      !this.props.editedDeviceForProvision.devEUI ||
      !this.props.editedDeviceForProvision.appEUI ||
      !this.props.editedDeviceForProvision.appKey ||
      !this.props.editedDeviceForProvision.deviceProfileId
  }

  updateDeviceForProvision () {
    this.props.dispatch(updateEditedDeviceForProvision(this.props.editedDeviceForProvision))
  }

  deleteDeviceForProvision () {
    if (window.confirm(this.props.t('settings_dialog.delete_confirmation_short'))) {
      this.props.dispatch(deleteDeviceForProvision(this.props.editedDeviceForProvision.id))
    }
  }

  getActions () {
    return [
      <Button key='delete' color='inherit' onClick={() => this.deleteDeviceForProvision()}>{this.props.t('settings_dialog.delete')}</Button>,
      <Button key='cancel' color='primary' onClick={() => this.closeDialog()}>{this.props.t('settings_dialog.cancel')}</Button>,
      <Button
        key='save'
        disabled={this.disabled()}
        onClick={() => this.updateDeviceForProvision()}
        color='secondary'
      >{this.props.t('settings_dialog.ok')}
      </Button>
    ]
  }

  valueChanged = (field, value) => {
    this.props.dispatch(editedDeviceForProvisionValuesChanged({ ...this.props.editedDeviceForProvision, [field]: value }))
  }

  render () {
    return (
      <Dialog
        open={this.props.editDeviceForProvisionDialogOpen}
      >
        <DialogTitle>
          {this.props.t('settings_dialog.edit_meter')}
        </DialogTitle>
        <DialogContent>
          <DeviceForProvisionFields
            deviceForProvision={this.props.editedDeviceForProvision}
            valueChanged={this.valueChanged}
            deviceProfiles={this.props.deviceProfiles}
            loadingDeviceProfiles={this.props.loadingDeviceProfiles}
          />
        </DialogContent>
        <DialogActions>
          {this.getActions()}
        </DialogActions>
      </Dialog>
    )
  }
}

function mapStateToProps (state) {
  const { devicesForProvisionReducer } = state.vesimittari
  const {
    editedDeviceForProvision,
    editDeviceForProvisionDialogOpen,
    loadingDeviceProfiles,
    deviceProfiles
  } = devicesForProvisionReducer
  return {
    editedDeviceForProvision,
    editDeviceForProvisionDialogOpen,
    loadingDeviceProfiles,
    deviceProfiles
  }
}

export default withTranslation()(connect(mapStateToProps)(EditDeviceForProvisionDialog))
