import { API_URL } from '../../../constants/urls'
import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import fileDownload from 'js-file-download'
import { withTranslation } from 'react-i18next'

import {
  closeGenerateCSVFromGivenSerialDialog,
  newGenerateCSVFromSerialValuesChanged
} from '../../../redux/devices-for-provision/devices-for-provision-actions'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'

class GenerateCSVFromGivenSerialDialog extends React.Component {
  closeDialog () {
    this.props.dispatch(closeGenerateCSVFromGivenSerialDialog())
  }

  t = this.props.t

  getActions () {
    return [
      <Button key='cancel' color='primary' onClick={() => this.closeDialog()}>Peruuta</Button>,
      <Button
        key='generate'
        onClick={() => this.fetchDataForCSV(this.props.csvExportStart)}
        color='secondary'
      >
        {this.props.t('generate_csv')}
      </Button>

    ]
  }

  valueChanged = (value) => {
    this.props.dispatch(newGenerateCSVFromSerialValuesChanged(value))
  }

  fetchDataForCSV = async (serial) => {
    // npm install js-file-download -s
    fetch(`${API_URL}/device_for_provision/${serial}/data_for_csv`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`
      }
    })
      .then(response => response.json())
      .then(response => {
        this.generateCSV(response)
      })
      .catch(e => {
        window.alert('Tapahtui virhe: ' + e)
      })
  }

  generateCSV = (data) => {
    const csvData = data.map(data => [data.serialNumber, data.devEUI, data.appEUI, data.appKey])
    const joinDataAndHeader = `Serial number, DevEUI, AppEUI, AppKey \n${csvData.join('\n')}`
    fileDownload(joinDataAndHeader, 'serials.csv')
  }

  render () {
    return (
      <Dialog
        open={this.props.generateCSVDialogOpen ?? false}
      >
        <DialogTitle>Hae ja tulosta CSV sarjanumerolla</DialogTitle>
        <DialogContent>
          <Grid item>
            <TextField
              fullWidth
              id='dfp-serialNumber'
              onChange={(event) => this.valueChanged(event.target.value)}
              required
              label='Serial number'
              variant='standard'
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {this.getActions()}
        </DialogActions>
      </Dialog>
    )
  }
}

function mapStateToProps (state) {
  const { devicesForProvisionReducer } = state.vesimittari
  const {
    GenerateCSVFromGivenSerialDialog,
    generateCSVDialogOpen,
    csvExportStart
  } = devicesForProvisionReducer
  return {
    GenerateCSVFromGivenSerialDialog,
    generateCSVDialogOpen,
    csvExportStart
  }
}

export default withTranslation()(connect(mapStateToProps)(GenerateCSVFromGivenSerialDialog))
